/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import api from './api'

/**
     * Valida se o CNPJ cadastrado já foi usado por mais algum usuário
     *
     * @param cnpj CNPJ para validar
     */
export const validaCpf = (cnpj, id) => {
  if (cnpj) {
    return new Promise((resolve, reject) => {
      const response = id ? api.existeCpf(cnpj, id) : api.existeCpf(cnpj)
      response
        .then(payload => {
          if (payload.data) {
            reject(new Error('CPF já cadastrado no sistema, por favor verifique!'))
          }
          resolve(null)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  return null
}

/**
     * Valida se o login cadastrado já foi usado por mais algum usuário
     *
     * @param login Login para validar
     */
export const validaEmail = (login, id) => {
  if (login) {
    return new Promise((resolve, reject) => {
      const response = id ? api.existeLogin(login, id) : api.existeLogin(login)
      response.then(payload => {
        if (payload.data) {
          reject(new Error('Login já cadastrado no sistema, por favor verifique!'))
        }
        resolve(null)
      })
        .catch(err => {
          reject(err)
        })
    })
  }
  return null
}
