import { ref, onBeforeMount } from '@vue/composition-api'
import api from '@/app/usuario/shared/services/api'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventBus from '@app/shared/mixins/event-bus/eventBus'

export default function useUsuario(modoEdicao, validacao) {
  const toast = useToast()
  const form = ref(null)
  const dadosAcesso = ref(null)
  const { router } = useRouter()

  onBeforeMount(() => {
    form.value = {
      cpf: '',
      nomeCompleto: '',
      contador: false,
      crc: '',
      dadosAcesso: {
        login: '',
        senha: '',
        confirmarSenha: '',
        alterarSenha: false,
      },
      empresasVinculadas: {
        vinculadas: [],
        naoVinculadas: [],
        totalVinculadas: 0,
        totalNaoVinculadas: 0,
        id: 0,
      },
    }
  })

  const carregar = id => {
    api.getUsuario(id)
      .then(payload => {
        form.value = payload.data
      })
      .catch(err => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Não foi possível carregar as empresas.',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
        throw err
      })
      .finally(() => {
        EventBus.$emit('finalizaLoading')
      })
  }

  const carregarEmpresasParaVinculo = () => {
    api.getEmpresasParaVinculo()
      .then(payload => {
        form.value.empresasVinculadas.naoVinculadas = payload.data
        form.value.empresasVinculadas.totalNaoVinculadas = payload.data.length
        form.value.empresasVinculadas.totalVinculadas = 0
      })
      .catch(err => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Não foi possível carregar as empresas para vinculo.',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
        throw err
      })
      .finally(() => {
        EventBus.$emit('finalizaLoading')
      })
  }

  const salvar = () => {
    if (validacao && (validacao.value.login || validacao.value.cpf)) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: validacao.value.cpf || validacao.value.login,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
      return null
    }

    if (!dadosAcesso.value.valido()) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Senhas não são iguais',
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
      return null
    }

    if (modoEdicao && form.value.id > 0) {
      return new Promise((resolve, reject) => {
        api.alterarUsuario(form.value.id, form.value)
          .then(() => {
            resolve('Usuário atualizado com sucesso')
            router.go(-1)
          })
          .catch(err => {
            reject(err)
            if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
              router.go(-1)
            }
          })
      })
    }
    return new Promise((resolve, reject) => {
      if ((form.value.administrador !== undefined && form.value.administrador === true) || (form.value.permissoes !== undefined && form.value.permissoes.length > 0)) {
        api.salvarUsuario(form.value)
          .then(() => {
            resolve('Usuário cadastrado com sucesso')
            router.go(-1)
          })
          .catch(err => {
            reject(err)
            if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
              router.go(-1)
            }
          })
      } else {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Necessário selecionar ao menos uma tela',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      }
    })
  }

  const cancelar = () => {
    router.go(-1)
  }

  return {
    salvar,
    carregar,
    cancelar,
    form,
    dadosAcesso,
    carregarEmpresasParaVinculo,
  }
}
